import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

document.querySelectorAll('.element-comparison').forEach((el) => {
  const titles = el.querySelector('.comparison-titles')
  const stressBars = el.querySelector('.stress-bars')
  const items = el.querySelectorAll('.comparison-item')

  const bottomEls = el.querySelectorAll('.comparison-from-bottom')
  const leftEls = el.querySelectorAll('.comparison-from-left')
  const rightEls = el.querySelectorAll('.comparison-from-right')

  gsap.set(bottomEls, { opacity: 0, y: '50%' })
  gsap.set(leftEls, { opacity: 0, x: '-100%' })
  gsap.set(rightEls, { opacity: 0, x: '100%' })

  const common = {
    duration: 0.6,
    stagger: 0.2,
    ease: 'power4',
  }

  const fromBottom = {
    ...common,
    opacity: 1,
    y: 0,
  }

  const fromLeftRight = {
    ...common,
    opacity: 1,
    x: 0,
  }

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: el,
      start: 'top 80%',
      markers: false,
    },
  })

  const titlesTimeline = gsap.timeline()

  titlesTimeline.add('start')
  titlesTimeline.to(titles.querySelector('.comparison-from-bottom'), fromBottom, 'start')
  titlesTimeline.to(titles.querySelector('.comparison-from-left'), fromLeftRight, 'start')
  titlesTimeline.to(titles.querySelector('.comparison-from-right'), fromLeftRight, 'start')

  timeline.add(titlesTimeline)

  if (stressBars) {
    const stressTimeline = gsap.timeline()

    stressTimeline.to(stressBars.querySelector('.stress-bar-low'), {
      ...common,
      duration: 0.8,
      width: '10%',
    })

    stressTimeline.to(stressBars.querySelector('.stress-bar-high'), {
      ...common,
      duration: 0.8,
      width: '90%',
    })

    timeline.add(stressTimeline)
  }

  items.forEach((item) => {
    const itemTimeline = gsap.timeline()

    itemTimeline.add('start')
    itemTimeline.to(item.querySelector('.comparison-from-bottom'), fromBottom, 'start')
    itemTimeline.to(item.querySelector('.comparison-from-left'), fromLeftRight, 'start')
    itemTimeline.to(item.querySelector('.comparison-from-right'), fromLeftRight, 'start')

    timeline.add(itemTimeline)
  })
})
